import { t, Trans } from '@lingui/macro'
import { CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { fetcher } from '@src/Fetch/helpers'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import Link from 'next/link'
import React, { useState } from 'react'
import AuthEmailInput from '@src/Auth/AuthEmailInput'
import AuthTelInput from '@src/Auth/AuthTelInput'
import { useRouter } from 'next/router'

const AuthForm = ({
  isSubmitting,
  setIsSubmitting,
  setLookupToken,
}: {
  isSubmitting: boolean
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  setLookupToken: React.Dispatch<React.SetStateAction<string | null>>
}) => {
  const router = useRouter()
  const { typography } = useTypographyContext()
  const fetch = fetcher({ r_number: null, token: null, consumeError: false })

  const [credentialType, setCredentialType] = useState<'phone' | 'email'>(
    'phone',
  )

  const [signInInitiated, setSignInInitiated] = useState(false)
  const [phone, setPhone] = useState('')

  const [email, setEmail] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(false)

  const sentToCopy = credentialType === 'phone' ? phone : email

  if (signInInitiated) {
    return (
      <div
        css={{
          height: '100%',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: spacing.colossal,
          }}
        >
          <CheckCircleIcon
            sx={{
              height: 30,
              width: 30,
              color: colors.dish[500],
            }}
          />
          <div
            css={{
              ...typography.title.large,
              paddingTop: spacing.comfy,
              paddingBottom: spacing.base,
            }}
          >
            <Trans>Sign-in link sent</Trans>
          </div>
          <div
            css={{
              ...typography.title.small,
              color: colors.neutrals[300],
              textAlign: 'center',
            }}
          >
            <Trans>
              Please click the link sent to{' '}
              <span css={{ fontWeight: 700 }}>{sentToCopy}</span>
            </Trans>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div css={{ padding: spacing.spacious }}>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <div
          css={{
            width: '100%',
            maxWidth: 600,
            paddingTop: spacing.spacious,
          }}
        >
          <div
            css={{
              paddingBottom: spacing.normal,
              ...typography.title.large,
            }}
          >
            <Trans>Welcome</Trans>
          </div>
          <div
            css={{
              paddingBottom: spacing.base,
              color: colors.neutrals[200],
              ...typography.title.small,
            }}
          >
            {credentialType === 'phone' ? (
              <Trans>We&apos;ll text you a link to sign you in.</Trans>
            ) : (
              <Trans>We&apos;ll email you a link to sign you in.</Trans>
            )}
          </div>
          <form
            method="POST"
            onSubmit={async (e) => {
              e.preventDefault()
              e.stopPropagation()

              setIsSubmitting(true)

              const url =
                credentialType === 'phone'
                  ? 'initiate_sms_user_login'
                  : 'initiate_sms_user_login_by_email'

              const body =
                credentialType === 'phone'
                  ? {
                      client_phone_number: `+1${phone.replaceAll('-', '')}`,
                    }
                  : { email_address: email }

              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...body, redirect: router.pathname }),
              })

              setLookupToken(response)

              setIsSubmitting(false)
              setSignInInitiated(true)
            }}
          >
            <div css={{ paddingBlock: spacing.normal }}>
              {credentialType === 'phone' ? (
                <AuthTelInput
                  label={t`Phone number`}
                  value={phone}
                  onChange={({ value, isValid }) => {
                    setIsPhoneValid(isValid)
                    setPhone(value)
                  }}
                />
              ) : (
                <div
                  css={{
                    paddingBottom: spacing.normal + spacing.hairline,
                  }}
                >
                  <AuthEmailInput
                    label={t`Email`}
                    value={email}
                    onChange={setEmail}
                  />
                </div>
              )}
            </div>
            <button
              type="submit"
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingInline: spacing.spacious,
                paddingBlock: spacing.normal,
                background: colors.primary2[500],
                border: `1px solid ${colors.primary2[500]}`,
                borderRadius: constants.borderRadius.medium,
                color: colors.white[500],
                cursor: 'pointer',
                ...typography.button.large,
                ':hover:enabled': {
                  background: colors.primary2[300],
                },
                ':active:enabled': {
                  background: colors.primary2[800],
                  borderColor: colors.primary2[800],
                },
                ':disabled': {
                  opacity: constants.opacity.quarter,
                  cursor: 'not-allowed',
                },
              }}
              disabled={
                (credentialType === 'phone' && !isPhoneValid) ||
                (credentialType === 'email' &&
                  email.match(/[A-Za-z0-9]+@[A-Za-z0-9]+\.[A-Za-z0-9]+/) ==
                    null)
              }
            >
              {isSubmitting ? (
                <CircularProgress sx={{ color: colors.white[500] }} size={20} />
              ) : (
                <Trans>Send sign-in link</Trans>
              )}
            </button>
          </form>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: spacing.base,
              gap: spacing.base,
            }}
          >
            <button
              css={{
                background: 'none',
                border: 'none',
                color: colors.primary2[500],
                ...typography.button.medium,
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                setCredentialType(
                  credentialType === 'phone' ? 'email' : 'phone',
                )
                setPhone('')
                setEmail('')
              }}
            >
              {credentialType === 'phone' ? (
                <Trans>Get link by email instead</Trans>
              ) : (
                <Trans>Get link by text instead</Trans>
              )}
            </button>
            {credentialType === 'phone' && (
              <div css={{ textAlign: 'center' }}>
                <Trans>
                  By logging in with SMS you agree to receive a one-time access
                  link at the number you have provided. Message and data rates
                  may apply. For more details see our{' '}
                  <Link
                    href="/terms-of-use"
                    css={{
                      textDecoration: 'none',
                      fontWeight: 700,
                      color: colors.primary2[500],
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    shallow={true}
                  >
                    Terms and Conditions
                  </Link>{' '}
                  and our{' '}
                  <Link
                    href="/privacy-policy"
                    css={{
                      textDecoration: 'none',
                      fontWeight: 700,
                      color: colors.primary2[500],
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    shallow={true}
                  >
                    Privacy Policy
                  </Link>
                  .
                </Trans>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthForm
