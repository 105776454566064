import {
  GdClientAuthenticationReturnMap,
  GdCmaReturnMap,
} from '@kjt01/greendot-wasm'
import { useMemo } from 'react'
import useSWR, { SWRResponse, mutate } from 'swr'
import { PublicConfiguration } from 'swr/_internal'
import { useSWRConfig } from 'swr'
import { useUserContext } from '@src/User'

export const fetcher =
  ({
    r_number,
    token,
    user_name,
    consumeError,
  }: {
    r_number: string | null
    token: string | null
    user_name?: string | null
    consumeError?: boolean
  }) =>
  async (url: string | unknown[], options?: RequestInit) => {
    const { headers, ...rest } = options || {}

    const [urlString = '', body = null] = typeof url === 'string' ? [url] : url

    const response = await fetch(`/api/v1/${urlString}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        ...(token != null ? { Authentication: `Bearer ${token}` } : {}),
        ...headers,
      },
      body: JSON.stringify({
        token,
        user_name,
        r_number,
        ...(body ?? {}),
      }),
      ...rest,
    })

    if (response.status === 401) {
      mutate('/me', null, false)
    }

    if (response.status >= 400) {
      throw response
    }

    const contentType = response.headers.get('content-type')

    let json
    if (contentType && contentType.includes('text/plain')) {
      const text = await response.text()
      if (text.startsWith('Could not deserialize incoming request')) {
        throw response
      }
      json = JSON.parse(text)
    } else {
      json = await response.json()
    }

    if (response.status >= 400 || json.Err != null) {
      if (consumeError) return json
      throw response
    }

    try {
      if (json.Ok != null) {
        return json.Ok
      }

      return json
    } catch (error) {
      throw response
    }
  }

export const getQueryString = (queryParams: Record<string, string | null>) => {
  return Object.entries(queryParams)
    .filter(([_, v]) => !!v)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
}

type WondersReturnMap = GdCmaReturnMap & GdClientAuthenticationReturnMap

export function useWonders<T extends keyof WondersReturnMap>(
  args: [T, ...Array<unknown>] | null,
): SWRResponse<WondersReturnMap[T]> {
  const [method, params, options] = useMemo(() => args || [], [args])

  const response = useSWR(
    method ? [method, params] : null,
    null,
    options as Partial<PublicConfiguration>,
  )

  return response
}

export const usePost = () => {
  const { user, me, r_number } = useUserContext()
  const { cache } = useSWRConfig()

  const poster = async <T extends keyof WondersReturnMap>(
    url: T,
    body: object,
    clearKeys: string[],
    onSuccess?: (result: WondersReturnMap[T]) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError?: (error: any) => void,
  ) => {
    clearKeys.forEach((key) => {
      for (const k of cache.keys()) {
        if (k.includes(key)) {
          cache.delete(k)
        }
      }
    })

    const fetch = fetcher({
      r_number,
      token: user.token,
      user_name: me?.phone_number,
      consumeError: true,
    })

    try {
      const result = await fetch([url, body])

      if (result.Err != null) throw result
      if (onSuccess != null) onSuccess(result)

      return result
    } catch (e) {
      if (onError != null) onError(e)
    }

    return null
  }

  return poster
}
