import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, Drawer, Paper, useMediaQuery, useTheme } from '@mui/material'
import { useWonders } from '@src/Fetch/helpers'
import LayoutButton from '@src/Layout/LayoutButton'
import { PAGES, shouldShowPageForLocation } from '@src/Layout/helpers'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import { useUserContext } from '@src/User'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useFlags } from 'launchdarkly-react-client-sdk'

const LayoutNavDrawer = ({
  isMobileOpen,
  handleDrawerToggle,
}: {
  isMobileOpen: boolean
  handleDrawerToggle: () => void
}) => {
  const { cmaAddressAlerts } = useFlags()
  const router = useRouter()
  const { i18n } = useLingui()
  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'))

  const { typography } = useTypographyContext()
  const { me, logout, isAdmin, user, r_number } = useUserContext()

  const { data: restaurant } = useWonders(
    router.pathname === '/l/[loginToken]' ||
      r_number == null ||
      user.token == null
      ? null
      : ['restaurant_info'],
  )

  const drawerWidth = 344

  return (
    <nav>
      <Drawer
        variant="temporary"
        anchor={'right'}
        transitionDuration={{
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen,
        }}
        open={isMobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            background: colors.white[100],
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            boxShadow: 'none',
            left: 'unset',
            top: 0,
          },
        }}
      >
        <Box
          onClick={handleDrawerToggle}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            flex: 1,
          }}
        >
          <Paper
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              boxShadow: 'none',
            }}
          >
            <Box
              sx={{
                gap: spacing.small,
                padding: `${spacing.normal}px`,
                paddingBottom: 0,
              }}
            >
              {PAGES.filter((p) => p.showInNavigation).map((page) => {
                if (isDesktopView && page.showInNavBar) return null

                if (shouldShowPageForLocation({ page, restaurant }) === false)
                  return null

                if (me == null && page.isPublic === false) return null
                if (page.adminOnly && !isAdmin) return null
                if (page.label == null) return null

                if (
                  page.path === '/address-management' &&
                  cmaAddressAlerts === false
                ) {
                  return null
                }

                if (page.path === '/addresses' && cmaAddressAlerts === true) {
                  return null
                }

                return (
                  <Link
                    key={page.path}
                    href={page.path}
                    css={{ textDecoration: 'none', color: 'unset' }}
                    shallow={true}
                  >
                    <LayoutButton
                      variant="light"
                      label={i18n._(page.label)}
                      isSelected={router.route === page.path}
                    />
                  </Link>
                )
              })}
            </Box>

            <div
              css={{
                paddingInline: spacing.normal,
                paddingTop: spacing.normal,
                paddingBottom: spacing.normal,
              }}
            >
              <button
                type="button"
                css={{
                  width: '100%',
                  border: `1px solid ${colors.white[600]}`,
                  borderRadius: constants.borderRadius.medium,
                  background: colors.white[600],
                  paddingBlock: spacing.normal,
                  paddingInline: spacing.spacious,
                  ...typography.button.large,
                  color: colors.neutrals[500],
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  ':hover': {
                    border: `1px solid ${colors.neutrals[400]}`,
                  },
                  ':active': {
                    border: `1px solid ${colors.white[900]}`,
                    background: colors.white[900],
                    color: colors.neutrals[50],
                  },
                }}
                onClick={() => {
                  if (me != null) {
                    logout()
                  } else {
                    router.push('/')
                  }
                }}
              >
                {me != null ? <Trans>Logout</Trans> : <Trans>Login</Trans>}
              </button>
            </div>
          </Paper>
        </Box>
      </Drawer>
    </nav>
  )
}

export default LayoutNavDrawer
