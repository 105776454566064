import { useLocalStorage } from '@src/LocalStorage/helpers'
import { createContext, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

interface CMA_TypographyContext {
  scale: number
  setScale: React.Dispatch<React.SetStateAction<number>>
  typography: ReturnType<typeof getTypography>
}

export const TypographyContext = createContext<CMA_TypographyContext | null>(
  null,
)

export const useTypographyContext = () => {
  const context = useContext(TypographyContext)

  if (context == null) {
    throw new Error('useTypographyContext must be within TypographyProvider')
  }

  return context
}

const Typography = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()
  const { ft } = router.query

  const [scale, setScale] = useLocalStorage<number>({
    key: 'Typography.fontScale',
    initialState: 1,
  })

  useEffect(() => {
    if (ft === 'lg') {
      setScale(1.5)
    }
  }, [ft, setScale])

  return (
    <TypographyContext.Provider
      value={{
        scale,
        setScale,
        typography: getTypography({ scale }),
      }}
    >
      {children}
    </TypographyContext.Provider>
  )
}

export default Typography

import { Poppins } from 'next/font/google'

const poppinsFontFamily = Poppins({
  subsets: ['latin'],
  weight: ['500', '600'],
})

const fontSize = {
  display: {
    large: 57,
    medium: 45,
    small: 36,
  },
  headline: {
    large: 32,
    medium: 28,
    small: 24,
  },
  title: {
    large: 22,
    medium: 16,
    small: 14,
  },
  label: {
    large: 14,
    medium: 12,
    small: 11,
  },
  body: {
    large: 16,
    medium: 14,
    small: 12,
  },
  section: {
    heading: 18,
  },
  button: {
    xlarge: 18,
    large: 16,
    medium: 14,
    small: 12,
  },
  lineItem: {
    text: 14,
  },
}

const lineHeight = {
  display: {
    large: 64,
    medium: 52,
    small: 44,
  },
  headline: {
    large: 40,
    medium: 36,
    small: 32,
  },
  title: {
    large: 28,
    medium: 24,
    small: 20,
  },
  label: {
    large: 20,
    medium: 16,
    small: 16,
  },
  body: {
    large: 24,
    medium: 20,
    small: 16,
  },
  section: {
    heading: 27,
  },
  button: {
    xlarge: 27,
    large: 24,
    medium: 21,
    small: 12,
  },
  lineItem: {
    text: 21,
  },
}

const getTypography = ({ scale }: { scale: number }) => ({
  display: {
    large: {
      fontSize: fontSize.display.large * scale,
      lineHeight: `${lineHeight.display.large * scale}px`,
      fontWeight: 400,
    },
    medium: {
      fontSize: fontSize.display.medium * scale,
      lineHeight: `${lineHeight.display.medium * scale}px`,
      fontWeight: 400,
    },
    small: {
      fontSize: fontSize.display.small * scale,
      lineHeight: `${lineHeight.display.small * scale}px`,
      fontWeight: 400,
    },
  },
  headline: {
    large: {
      fontSize: fontSize.headline.large * scale,
      lineHeight: `${lineHeight.headline.large * scale}px`,
      fontWeight: 400,
    },
    medium: {
      fontSize: fontSize.headline.medium * scale,
      lineHeight: `${lineHeight.headline.medium * scale}px`,
      fontWeight: 400,
    },
    small: {
      fontSize: fontSize.headline.small * scale,
      lineHeight: `${lineHeight.headline.small * scale}px`,
      fontWeight: 400,
    },
  },
  title: {
    large: {
      fontSize: fontSize.title.large * scale,
      lineHeight: `${lineHeight.title.large * scale}px`,
      fontWeight: 400,
    },
    medium: {
      fontSize: fontSize.title.medium * scale,
      lineHeight: `${lineHeight.title.medium * scale}px`,
      fontWeight: 600,
      letterSpacing: 0.15,
    },
    mediumRegular: {
      fontSize: fontSize.title.medium * scale,
      lineHeight: `${lineHeight.title.medium * scale}px`,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    small: {
      fontSize: fontSize.title.small * scale,
      lineHeight: `${lineHeight.title.small * scale}px`,
      fontWeight: 400,
      letterSpacing: 0.1,
    },
  },
  label: {
    large: {
      fontSize: fontSize.label.large * scale,
      lineHeight: `${lineHeight.label.large * scale}px`,
      fontWeight: 700,
      letterSpacing: 0.1,
    },
    medium: {
      fontSize: fontSize.label.medium * scale,
      lineHeight: `${lineHeight.label.medium * scale}px`,
      fontWeight: 400,
      letterSpacing: 0.5,
    },
    small: {
      fontSize: fontSize.label.small * scale,
      lineHeight: `${lineHeight.label.small * scale}px`,
      fontWeight: 400,
      letterSpacing: 0.5,
    },
  },
  body: {
    large: {
      fontSize: fontSize.body.large * scale,
      lineHeight: `${lineHeight.body.large * scale}px`,
      fontWeight: 400,
      letterSpacing: 0.5,
    },
    medium: {
      fontSize: fontSize.body.medium * scale,
      lineHeight: `${lineHeight.body.medium * scale}px`,
      fontWeight: 400,
      letterSpacing: 0.25,
    },
    small: {
      fontSize: fontSize.body.small * scale,
      lineHeight: `${lineHeight.body.small * scale}px`,
      fontWeight: 600,
      letterSpacing: 0.4,
    },
  },
  section: {
    heading: {
      ...poppinsFontFamily.style,
      fontSize: fontSize.section.heading * scale,
      lineHeight: `${lineHeight.section.heading * scale}px`,
      fontWeight: 600,
    },
  },
  button: {
    xlarge: {
      ...poppinsFontFamily.style,
      fontSize: fontSize.button.xlarge * scale,
      lineHeight: `${lineHeight.button.xlarge * scale}px`,
      fontWeight: 500,
    },
    large: {
      ...poppinsFontFamily.style,
      fontSize: fontSize.button.large * scale,
      lineHeight: `${lineHeight.button.large * scale}px`,
      fontWeight: 500,
    },
    medium: {
      ...poppinsFontFamily.style,
      fontSize: fontSize.button.medium * scale,
      lineHeight: `${lineHeight.button.medium * scale}px`,
      fontWeight: 500,
    },
    small: {
      ...poppinsFontFamily.style,
      fontSize: fontSize.button.small * scale,
      lineHeight: `${lineHeight.button.small * scale}px`,
      fontWeight: 500,
      letterSpacing: 0.15,
    },
  },
  lineItem: {
    text: {
      ...poppinsFontFamily.style,
      fontSize: fontSize.lineItem.text * scale,
      lineHeight: `${lineHeight.lineItem.text * scale}px`,
      fontWeight: 500,
    },
  },
})
