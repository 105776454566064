import { createContext, useContext, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { constants, spacing } from '@src/Styles'

type SnackbarAction = {
  label: string
  onClick: () => void
}

interface SnackbarContext {
  onError: (message: string, action?: SnackbarAction) => void
  onSuccess: (message: string, action?: SnackbarAction) => void
}

export const SnackbarContext = createContext<SnackbarContext | null>(null)

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext)

  if (context == null) {
    throw new Error('useSnackbarContext must be within SnackbarProvider')
  }

  return context
}

const GlobalSnackbar = ({ children }: { children: React.ReactNode }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [action, setAction] = useState<SnackbarAction | undefined>(undefined)
  const [severity, setSeverity] = useState<'error' | 'success'>('error')

  return (
    <>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={action != null ? null : 3000}
        onClose={() => setIsSnackbarOpen(false)}
        sx={{
          top: { xs: 0, sm: 6 },
          right: { xs: 0, sm: 6 },
          bottom: { xs: 'unset', sm: 'unset' },
          left: { xs: 0, sm: 'unset' },
        }}
      >
        <Alert
          onClose={action != null ? undefined : () => setIsSnackbarOpen(false)}
          severity={severity}
          variant="filled"
          sx={{
            width: '100%',
            height: { xs: 64, sm: 'unset' },
            alignItems: 'center',
            borderRadius: { xs: 0, sm: 1 },
          }}
        >
          <div
            css={{ display: 'flex', alignItems: 'center', gap: spacing.base }}
          >
            <div>{message}</div>
            {action != null ? (
              <button
                css={{
                  border: 'none',
                  borderRadius: constants.borderRadius.small,
                  paddingBlock: spacing.small,
                  paddingInline: spacing.base,
                }}
                onClick={() => action.onClick()}
              >
                {action.label}
              </button>
            ) : null}
          </div>
        </Alert>
      </Snackbar>

      <SnackbarContext.Provider
        value={{
          onSuccess: (message, action) => {
            setIsSnackbarOpen(true)
            setSeverity('success')
            setMessage(message)
            setAction(action)
          },
          onError: (message, action) => {
            setIsSnackbarOpen(true)
            setSeverity('error')
            setMessage(message)
            setAction(action)
          },
        }}
      >
        {children}
      </SnackbarContext.Provider>
    </>
  )
}

export default GlobalSnackbar
