import Intercom, { shutdown } from '@intercom/messenger-js-sdk'
import { useWonders } from '@src/Fetch/helpers'
import { useUserContext } from '@src/User'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { environment } from '@/environment'
import crypto from 'crypto'
import useSWR from 'swr'
import { useCallback } from 'react'

const IntercomMessenger = () => {
  const fetcher = useCallback(
    (url: string) => fetch(url).then((res) => res.json()),
    [],
  )

  const { data: { secretKey } = {} } = useSWR('/api/v1/intercom', fetcher)

  const { cmaIntercomWebMessenger } = useFlags()

  const { me, r_number, user } = useUserContext()
  const { data: restaurant } = useWonders(
    r_number == null || user.token == null ? null : [`restaurant_info`],
  )

  if (cmaIntercomWebMessenger === false) {
    shutdown()
    return null
  }

  if (
    r_number == null ||
    me?.wid == null ||
    me?.phone_number == null ||
    me?.language == null ||
    restaurant == null ||
    user == null ||
    secretKey == null
  )
    return null

  const hash = crypto
    .createHmac('sha256', secretKey)
    .update(me.wid)
    .digest('hex')

  Intercom({
    app_id: environment === 'production' ? 'tf91g5q4' : 'j1flec5a',
    user_id: me.wid,
    name: restaurant.parent_enterprise.name_complex.english_name,
    company: {
      company_id: r_number,
      name: restaurant.parent_enterprise.name_complex.english_name,
    },
    phone: me.phone_number,
    language_override: me.language === 'zh_cn' ? 'zh-CN' : 'en',
    user_hash: hash,
  })

  return null
}

export default IntercomMessenger
