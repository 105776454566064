import { msg, t } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'
import { colors } from '@src/Styles'
import { startCase } from 'lodash'
import {
  CsbCart,
  CsbCartDiffType,
  CsbCartDishItem,
  CsbCartModifierItem,
  CsbCartPremodItem,
  CsbCartVariant,
  CsbEntityHeader,
  CsbOrder,
  CsbPremodItem,
  CsbRestaurantInfo,
  LanguageWritten,
  WenumChannelSource,
  WenumOrderRushReason,
  WenumOrderVoidReason,
} from '@kjt01/greendot-wasm'
import dayjs from 'dayjs'
import { getTimeZoneDateTime } from '@src/DateTime/helpers'
import { parseCurrency } from '@src/Currency/helpers'

export const getCartLineItemColor = ({
  diffType,
  isComplete,
  isSelected,
  oldQuantity,
  quantity,
  variant,
}: {
  isComplete?: boolean
  isSelected?: boolean
  diffType: CsbCartDiffType | null
  oldQuantity: number | null
  quantity: number
  variant?: CsbCartVariant
}) => {
  if (isComplete === false) {
    return colors.error[500]
  }

  if (isSelected === true) {
    return colors.dish[500]
  }

  if (diffType === 'add') {
    return colors.dish[50]
  }

  if (diffType === 'remove') {
    return colors.error[50]
  }

  if (
    diffType === 'update' ||
    variant?.diff_type === 'update' ||
    (oldQuantity != null && oldQuantity !== quantity)
  ) {
    return colors.options[50]
  }

  return colors.transparent
}

export const getName = ({
  item: { header },
  locale,
}: {
  item: { header: CsbEntityHeader }
  locale: string
}) => {
  let name = header?.english_name || ''

  if (locale === 'zh') {
    const chineseName = header?.i18n_names['zh_cn']

    if (chineseName != null) {
      name = chineseName
    }
  }

  return name
}

const EMPTY_PREMOD: CsbCartPremodItem = {
  header: {
    wid: 'Wid(0,0,0,0,0)',
    english_name: '',
    code: '',
    description: '',
    i18n_names: {} as Record<LanguageWritten, string>,
  },
  item_type: 'prefix',
  premod_items: [],
  diff_type: null,
}

export const getPremods = (item: CsbCartModifierItem | CsbCartPremodItem) => {
  const { premod_items } = item

  if (premod_items.length === 0) {
    return [EMPTY_PREMOD]
  }

  if (premod_items.length === 1 && premod_items[0].diff_type === 'remove') {
    const diff_type: CsbCartDiffType = 'add'
    return [{ ...EMPTY_PREMOD, diff_type }, ...premod_items]
  }

  return premod_items
}

export const getDiffType = ({
  dish,
  modifier,
  subModifier,
  premod: premod,
  premodPremod: premodPremod,
}: {
  dish: CsbCartDishItem
  modifier: CsbCartModifierItem
  subModifier?: CsbCartModifierItem
  premod: CsbCartPremodItem
  premodPremod: CsbCartPremodItem
}) => {
  if (
    dish.diff_type === 'remove' ||
    modifier.diff_type === 'remove' ||
    (subModifier && subModifier.diff_type === 'remove') ||
    premod.diff_type === 'remove' ||
    premodPremod.diff_type === 'remove'
  ) {
    return 'remove' as CsbCartDiffType
  }

  if (premodPremod.diff_type != null) {
    return premodPremod.diff_type
  }

  if (premod.diff_type != null) {
    return premod.diff_type
  }

  if (subModifier && subModifier.diff_type != null) {
    return subModifier.diff_type
  }

  return modifier.diff_type
}

export const formatModifierName = ({
  name,
  premod,
  premodPremod,
  locale,
}: {
  name: string
  premod?: CsbPremodItem | CsbCartPremodItem
  premodPremod?: CsbPremodItem | CsbCartPremodItem
  locale: string
}): string => {
  return [
    premod?.item_type === 'prefix' &&
      getName({ item: premod, locale }).toLocaleUpperCase(),
    name,
    premod?.item_type === 'suffix' &&
      getName({ item: premod, locale }).toLocaleUpperCase(),
    premodPremod &&
      formatModifierName({ name: '', premod: premodPremod, locale }),
  ]
    .filter(Boolean)
    .join(' ')
}

export const getOrderLineItemColor = ({
  diffType,
  isComplete,
  isSelected,
  oldQuantity,
  quantity,
  variant,
}: {
  isComplete?: boolean
  isSelected?: boolean
  diffType: CsbCartDiffType | null
  oldQuantity: number | null
  quantity: number
  variant?: CsbCartVariant
}) => {
  if (isComplete === false) {
    return colors.error[500]
  }

  if (isSelected === true) {
    return colors.dish[500]
  }

  if (diffType === 'add') {
    return colors.dish[50]
  }

  if (diffType === 'remove') {
    return colors.error[50]
  }

  if (
    diffType === 'update' ||
    variant?.diff_type === 'update' ||
    (oldQuantity != null && oldQuantity !== quantity)
  ) {
    return colors.options[50]
  }

  return colors.transparent
}

export type VoidReason = WenumOrderVoidReason[keyof WenumOrderVoidReason]

export const VOID_REASONS: Partial<Record<VoidReason, MessageDescriptor>> = {
  card_declined: msg`Customer called back to pay but credit card got declined`,
  too_long: msg`Customer says order took too long`,
  customer_emergency: msg`Customer has emergency, needs to head out`,
  wrong_restaurant: msg`Customer said they called the wrong restaurant`,
  no_pick_up: msg`Customer did not pick up`,
  does_not_want: msg`Customer does not want the order`,
  customer_cancelled_previously: msg`Customer cancelled an order previously`,
  other: msg`Other`,
}

export type RushReason = WenumOrderRushReason[keyof WenumOrderRushReason]
export const RUSH_REASONS: Partial<Record<RushReason, MessageDescriptor>> = {
  too_long: msg`Order takes too long, please hurry up`,
  leave_soon: msg`Need to leave soon, please hurry up`,
  driver_ten_mins: msg`If driver does not arrive in 10 mins, customer will cancel`,
  meal_break: msg`Meal break will be over soon, please hurry up`,
  call_back: msg`Customer call back, please send the order again`,
  driver_no_show: msg`Driver called customer, but did not show up`,
  other: msg`Other`,
}

export type FoodOrderReason = keyof typeof FOOD_ORDER_REASONS
export const FOOD_ORDER_REASONS = {
  wrong_dish: msg`Wrong Dish`,
  wrong_size: msg`Wrong Size`,
  wrong_items: msg`Wrong Items`,
}

export type CustomerInfoReason = keyof typeof CUSTOMER_INFO_REASONS
export const CUSTOMER_INFO_REASONS = {
  phone_number: msg`Phone Number`,
  address: msg`Address`,
  delivery_fee: msg`Delivery Fee`,
  credit_card_information: msg`Credit Card Information`,
}

export type OtherReason = keyof typeof OTHER_REASONS
export const OTHER_REASONS = {
  customer_service: msg`Customer Service`,
  order_type: msg`Order Type`,
  coupon: msg`Coupon`,
}

export const getOtherReasonLabel = (reason: {
  kind: VoidReason | RushReason
  payload?: string
}) => {
  if (reason.kind === 'other') {
    return !reason.payload ? t`Other` : reason.payload
  }

  return startCase(reason.kind)
}

export const getOrderRootId = (order: CsbOrder) => {
  return {
    kind: order.order_snapshot_id.kind,
    payload: {
      ...(order.order_snapshot_id.kind === 'order1'
        ? {
            order_root_wid: order.order_snapshot_id.payload.order_root_wid,
          }
        : {
            order2_root_wid: order.order_snapshot_id.payload.order2_root_wid,
          }),
    },
  }
}

export const getOrderRootWid = (order: CsbOrder) => {
  return order.order_snapshot_id.kind === 'order1'
    ? order.order_snapshot_id.payload.order_root_wid
    : order.order_snapshot_id.payload.order2_root_wid
}

export const getOrderVersion = (order: CsbOrder) => {
  return order.order_snapshot_id.kind === 'order1'
    ? order.order_snapshot_id.payload.order_version_wid
    : order.order_snapshot_id.payload.order2_event_seqno
}

export const PAYMENT_TYPES = {
  cash: [
    'cash',
    'apple_pay',
    'google_pay',
    'venmo',
    'check',
    'cash_app',
    'gift_card',
    'customer_credit',
    'samsung_pay',
  ],
  credit: [
    'visa',
    'master_card',
    'american_express',
    'discover',
    'external_credit_card',
  ],
  compensated: ['tarro_paid'],
}

export const CREDIT_CARD_PAYMENTS = [
  'visa',
  'master_card',
  'american_express',
  'discover',
  'external_credit_card',
]

export const CASH_PAYMENTS = [
  'cash',
  'apple_pay',
  'google_pay',
  'venmo',
  'check',
  'cash_app',
  'gift_card',
  'customer_credit',
  'samsung_pay',
]

export const hasAdyenPayment = (cart: CsbCart) => {
  return (
    cart.cart_payments.find(
      (payment) =>
        payment.details.kind === 'adyen' &&
        parseCurrency(payment.amount_paid) > 0,
    ) != null
  )
}

export const isAdyenEnabled = (restaurant: CsbRestaurantInfo) => {
  return (
    restaurant?.payment_configs?.credit_card?.payment_processor.kind === 'adyen'
  )
}

export const formatOrderCardAddress = (order: CsbOrder) => {
  if (order.order_metadata?.delivery_complex?.delivery_address == null)
    return null

  return [
    order.order_metadata.delivery_complex.delivery_address?.address1?.replace(
      'Avenue',
      'Ave',
    ),
    order.order_metadata.delivery_complex.delivery_address?.address2,
    order.order_metadata.delivery_complex.address_type_note,
  ]
    .filter(Boolean)
    .join(', ')
}

export const getGoogleMapsURL = (order: CsbOrder) => {
  const BASE = 'https://www.google.com/maps/search/?api=1'

  if (order.order_metadata?.delivery_complex?.delivery_address == null)
    return null

  const components = [
    order.order_metadata.delivery_complex.delivery_address.address1,
    order.order_metadata.delivery_complex.delivery_address.address2,
    order.order_metadata.delivery_complex.delivery_address.city,
    order.order_metadata.delivery_complex.delivery_address.province?.split(
      '-',
    )[1],
    order.order_metadata.delivery_complex.delivery_address.postal_code?.split(
      '-',
    )[1],
  ]
    .filter(Boolean)
    .join(',')

  return new URL(`${BASE}&query=${encodeURIComponent(components)}`)
}

export const ALL_REASONS = {
  ...FOOD_ORDER_REASONS,
  ...CUSTOMER_INFO_REASONS,
  ...OTHER_REASONS,
}

export type DEFAULT_REASON = keyof typeof ALL_REASONS

export type SubmittedMistakes = {
  mistakes: Array<DEFAULT_REASON | string>
  dateTime: string
}

export const isOrderDayOld = ({
  order,
  restaurant,
}: {
  order: CsbOrder
  restaurant: CsbRestaurantInfo
}) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const now = getTimeZoneDateTime({
    timestamp: null,
    timeZone: restaurant?.location?.time_zone ?? undefined,
  })

  const nowInMilliseconds = new Date(now).getTime()

  const orderTimeNextDay = getTimeZoneDateTime({
    timestamp: dayjs(order?.local_datetime)
      .add(1, 'day')
      .startOf('day')
      .toDate(),
    timeZone: userTimeZone,
  })

  const orderTimeNextDayInMilliseconds = new Date(orderTimeNextDay).getTime()

  return nowInMilliseconds >= orderTimeNextDayInMilliseconds
}

export const ONLINE_SOURCES: Partial<WenumChannelSource>[] = [
  'uber_eats',
  'doordash',
  'grubhub',
  'online_order',
]

export const formatOrderNumber = (order: CsbOrder) => {
  const voicePlatformPrefix = order.order_source === 'voice_platform' ? 'C' : ''
  const onlineOrderPrefix = ONLINE_SOURCES.includes(order.order_source ?? '')
    ? 'O'
    : ''

  return `#${voicePlatformPrefix}${onlineOrderPrefix}${order.ticket_number}`
}
