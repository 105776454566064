import { i18n } from '@lingui/core'
import {
  fromCookie,
  fromNavigator,
  multipleDetect,
} from '@lingui/detect-locale'
import config from '@/lingui.config'

const COOKIE_KEY = 'lang'
export const DEFAULT_LOCALE = 'en'

export async function loadCatalog(
  l?: string | null,
  setHasLoaded?: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (l) {
    document.cookie = `${COOKIE_KEY}=${l}; Secure`
  }

  const preferredLocales = multipleDetect(
    fromCookie(COOKIE_KEY),
    fromNavigator(),
  )

  let matchedLocale
  loop: for (const preferredLocale of preferredLocales) {
    for (const configLocale of config.locales) {
      if (preferredLocale.toLocaleLowerCase().startsWith(configLocale)) {
        matchedLocale = configLocale
        break loop
      }
    }
  }

  const locale = l ?? matchedLocale ?? DEFAULT_LOCALE

  const { messages } = await import(`@lingui/loader!@/locales/${locale}.po`)
  i18n.load(locale, messages)
  i18n.activate(locale)

  if (setHasLoaded != null) setHasLoaded(true)
}
